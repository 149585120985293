import React from "react";
import { ControlType } from "./controltype";
import { InputNumber } from "./inputNumber";
import { InputString } from "./inputString";
import { InputCheckbox } from "./inputCheckbox";
import { InputEnum } from "./inputEnum";
import {
  Typography,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { InputContainer } from "./inputContainer";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuIcon from "@mui/icons-material/Menu";
import {
  ADD_ARRAY_ITEM,
  REMOVE_ARRAY_ITEMS,
  UPDATE_CHART_SETTINGS,
} from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";
import { ReactSortable } from "react-sortablejs";
import { InputColor } from "./inputColor";

export function PopupArray(props) {
  const {
    name,
    inputSettings,
    collectionItemIndex,
    collectionItemLabel,
    collectionLabel,
    groupLabel,
  } = props.popupProps.arrayProps;
  const { propertyControl } = inputSettings;

  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);

  // setList in sortable js updates list in first render, lets skip it to avoid re-render
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  const settingsArray = getObjValueByPath(chartUI, name);
  const dispatch = useDispatch();

  // if type is function, execute it with chart's settings as prop
  const inputType =
    typeof propertyControl.type === "function"
      ? propertyControl.type(chartSettings)
      : propertyControl.type || name;

  const hasHidden = settingsArray.some((item) => {
    return item.hidden === true;
  });

  const handleAddItem = () => {
    const controlType = typeof propertyControl.type;

    if (controlType === "function") {
      const value =
        propertyControl.type(chartSettings) === ControlType.Number
          ? 0
          : propertyControl.type(chartSettings) === ControlType.String
          ? " "
          : settingsArray[settingsArray.length - 1].value ||
            "rgba(17, 153, 238, 1)"; // if array consists from colors
      dispatch(ADD_ARRAY_ITEM(name, value));
    } else {
      const value =
        propertyControl.type === ControlType.Number
          ? 0
          : propertyControl.type === ControlType.String
          ? " "
          : settingsArray[settingsArray.length - 1].value ||
            "rgba(17, 153, 238, 1)"; // if array consists from colors
      dispatch(ADD_ARRAY_ITEM(name, value));
    }
  };

  const handleRemoveItems = () => {
    dispatch(REMOVE_ARRAY_ITEMS(name));
  };

  function getInputs(inputType) {
    function getInput(inputType, i) {
      switch (inputType) {
        case ControlType.Number:
          return (
            <InputNumber
              key={i}
              name={name + "." + i + ".value"}
              inputSettings={propertyControl}
              isArray
              index={i}
            />
          );
        case ControlType.String:
          return (
            <InputString
              key={i}
              name={name + "." + i + ".value"}
              inputSettings={propertyControl}
              isArray
              index={i}
            />
          );
        case ControlType.Color:
          return (
            <InputColor
              key={i}
              name={name + "." + i + ".value"}
              inputSettings={propertyControl}
              isArray
              index={i}
            />
          );
        case ControlType.Enum:
          // if options is function, execute it with chart's settings and index as prop
          const options =
            typeof propertyControl.options === "function"
              ? propertyControl.options(chartSettings, {
                  collectionGroupIndex: collectionItemIndex,
                  arrayIndex: i,
                })
              : propertyControl.options || [];

          // if titles is function, execute it with chart's settings as prop
          const optionTitles =
            typeof propertyControl.optionTitles === "function"
              ? propertyControl.optionTitles(chartSettings, {
                  collectionGroupIndex: collectionItemIndex,
                  arrayIndex: i,
                })
              : propertyControl.optionTitles || [];

          return (
            <InputEnum
              key={i}
              name={name + "." + i + ".value"}
              inputSettings={{
                ...propertyControl,
                options: options,
                optionTitles: optionTitles,
              }}
              isArray
              collectionGroupIndex={collectionItemIndex}
              arrayIndex={i}
            />
          );
        default:
          return null;
      }
    }
    return settingsArray.map(function (item, i) {
      const isHidden = settingsArray[i].hidden;
      return (
        <InputContainer isArray key={i}>
          {inputSettings.hideControls ? (
            ""
          ) : (
            <InputCheckbox
              key={i}
              name={name + "." + i}
              index={i}
              isHidden={isHidden}
              parentType={ControlType.Array}
            />
          )}
          {getInput(inputType, i)}
          <MenuIcon
            fontSize="small"
            className="iconDrag"
            style={{ color: "#999999" }}
          />
        </InputContainer>
      );
    });
  }

  const updateSettings = (list) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      dispatch(UPDATE_CHART_SETTINGS(name, list));
    }
  };

  return inputType !== undefined ? (
    <>
      {/* Header */}
      {groupLabel ? (
        <div>
          <Typography variant="h6">{groupLabel}</Typography>
        </div>
      ) : null}
      {collectionLabel ? (
        <div>
          <Typography variant="h6">{collectionLabel}</Typography>
        </div>
      ) : null}
      {collectionItemLabel !== undefined ? (
        <div>
          <Typography variant="body1">{collectionItemLabel}</Typography>
        </div>
      ) : null}
      {groupLabel || collectionLabel || collectionItemLabel !== undefined ? (
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      ) : null}
      {/* Array items */}
      <div
        style={{
          display: "flex",
          background: "#ffffff",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "70%",
            minHeight: 24,
            alignItems: "center",
            textAlign: "left",
            marginLeft: 8,
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography style={{ fontSize: "0.8125rem" }} noWrap>
            {inputSettings.title || name}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            width: "30%",
            minHeight: 24,
            alignItems: "center",
            marginRight: 8,
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          {inputSettings.hideControls ? (
            ""
          ) : (
            <ToggleButtonGroup size="small" style={{ width: "100%" }}>
              <ToggleButton
                style={{ padding: 0, width: "50%" }}
                color="primary"
                onClick={handleRemoveItems}
                disabled={!hasHidden}
                value="Decrement"
              >
                <RemoveIcon
                  fontSize="small"
                  color={"primary"}
                  style={{ opacity: !hasHidden ? 0.25 : 1 }}
                />
              </ToggleButton>
              <ToggleButton
                style={{ padding: 0, width: "50%" }}
                color="primary"
                onClick={handleAddItem}
                value="Increment"
              >
                <AddIcon
                  fontSize="small"
                  color={"primary"}
                  // style={{ opacity: inputValue === MAX ? 0.25 : 1 }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </div>
      </div>
      <Divider style={{ marginLeft: 8, marginRight: 8 }} />
      <div style={{ height: 8 }} />
      <ReactSortable list={settingsArray} setList={updateSettings}>
        {getInputs(inputType)}
      </ReactSortable>
      <div style={{ height: 8 }} />
    </>
  ) : null;
}

export default PopupArray;
