import React from "react";
import {
  Select,
  SelectChangeEvent,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { UPDATE_CHART_TYPE } from "../utils/actions";
import { useSelector, useDispatch } from "react-redux";
import { Charts } from "../charts/charts";

const DropDown = () => {
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    dispatch(UPDATE_CHART_TYPE(value));
  };

  //group charts by types
  const chartsGroupped = Object.entries(Charts).reduce(function (r, a) {
    r[a[1].group] = r[a[1].group] || [];
    r[a[1].group].push(a);
    return r;
  }, Object.create(null));

  return (
    <Select
      variant="standard"
      style={{ width: "100%", textAlign: "left", marginTop: 16 }}
      value={currentChartType}
      onChange={handleChange}
    >
      {Object.entries(chartsGroupped).map(function (group: any, j) {
        return group[1].map(function (chart, i) {
          return [
            i === 0 ? (
              <ListSubheader disableSticky style={{ pointerEvents: "none" }}>
                {chart[1].group}
              </ListSubheader>
            ) : null,
            <MenuItem key={i} value={chart[0]}>
              {chart[1].title}
            </MenuItem>,
          ];
        });
      })}
    </Select>
  );
};

export default DropDown;
