import React from "react";
import {
  TableContainer,
  Paper,
  Typography,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  UPDATE_CHART_SETTINGS,
  REMOVE_GROUP,
  ADD_GROUP,
} from "../utils/actions";
import "numeral/locales";
import { DataTable } from "./dataTable";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { numberToLetters } from "../utils/utils";
import { ReactSortable } from "react-sortablejs";
import { nanoid } from "nanoid";

// check, if selected column is not in black list or null (by default)
function isColumnValid(columnIndex: number | null, blackList: Array<number>) {
  return (
    columnIndex !== null && blackList.every((item) => item !== columnIndex)
  );
}

function buildDataByChartType(
  chartType,
  rows,
  dataMap,
  dataColumnsBlackList,
  dimensionsControls
) {
  switch (chartType) {
    case "Scatter":
      return buildScatterData(
        rows,
        dataMap,
        dataColumnsBlackList,
        dimensionsControls[Object.keys(dimensionsControls)[0]].path
      );
    case "Sankey":
      return buildSankeyData(rows, dataMap, dataColumnsBlackList);
    default:
      return buildDefaultData(
        rows,
        dataMap,
        dataColumnsBlackList,
        dimensionsControls
      );
  }
}

function buildSankeyData(rows, dataMap, dataColumnsBlackList) {
  const linkSource = "source";
  const linkTarget = "target";
  const linkValue = "value";
  const pathLinksValues = "linksValues";
  const pathNodesValues = "nodesValues";
  const sourceColumnIndex = dataMap[0][linkSource].value;
  const targetColumnIndex = dataMap[0][linkTarget].value;
  const valueColumnIndex = dataMap[0][linkValue].value;
  const linksValues: any[] = []; // linkValues array
  const nodesValues: any[] = []; // nodeValues array

  // if all columns are selected and valid, build data
  if (
    isColumnValid(sourceColumnIndex, dataColumnsBlackList[0]) &&
    isColumnValid(targetColumnIndex, dataColumnsBlackList[0]) &&
    isColumnValid(valueColumnIndex, dataColumnsBlackList[0])
  ) {
    for (let i = 0; i < rows.length; i++) {
      // convert links and target to strings, if they not
      const ls = "" + rows[i][sourceColumnIndex] + "";
      const lt = "" + rows[i][targetColumnIndex] + "";
      // check link's values for empty rows and null them
      const lv =
        rows[i][valueColumnIndex] === "" ? null : rows[i][valueColumnIndex];

      // construct value for linksValues
      const value = {
        collapsed: false,
        hidden: false,
        value: {
          nodesIdSource: ls,
          nodesIdTarget: lt,
          value: lv,
        },
      };
      linksValues.push(value);
    }

    // fill nodeValues from linkValues
    let nodesSet: any = new Set();
    for (let i = 0; i < linksValues.length; i++) {
      nodesSet.add(linksValues[i].value.nodesIdSource); // source node
      nodesSet.add(linksValues[i].value.nodesIdTarget); // target node
    }
    for (let item of nodesSet) {
      nodesValues.push({
        collapsed: false,
        hidden: false,
        value: {
          name: item,
          id: nanoid(10),
        },
      });
    }

    // replace source and target node names in linkValues with unique ids from nodesValues
    for (let i = 0; i < nodesValues.length; i++) {
      for (let j = 0; j < linksValues.length; j++) {
        const name = nodesValues[i].value.name;
        const id = nodesValues[i].value.id;
        if (linksValues[j].value.nodesIdSource === name) {
          linksValues[j].value.nodesIdSource = id;
        }
        if (linksValues[j].value.nodesIdTarget === name) {
          linksValues[j].value.nodesIdTarget = id;
        }
      }
    }
  }

  return {
    data: {
      [pathLinksValues]: {
        isVisible: true,
        items: linksValues,
      },
      [pathNodesValues]: {
        isVisible: true,
        items: nodesValues,
      },
    },
    dataTypes: {
      [linkSource]: new Set(["string"]),
      [linkTarget]: new Set(["string"]),
      [linkValue]: new Set(["number"]),
    },
  };
}

function buildScatterData(rows, dataMap, dataColumnsBlackList, path) {
  let data = {};
  let dataTypes = {};
  for (let key in dataMap[0]) {
    dataTypes[key] = new Set();
  }
  let seriesArr: any = []; // scatter series

  for (let i = 0; i < dataMap.length; i++) {
    let values: any = {};

    for (let key in dataMap[i]) {
      const columnIndex = dataMap[i][key].value;
      const dType = dataMap[i][key].dataType;

      if (isColumnValid(columnIndex, dataColumnsBlackList[i])) {
        values[key] = rows.map((item) => {
          const v =
            dType === "number" && item[columnIndex] === ""
              ? null
              : item[columnIndex];
          return {
            value: v,
            hidden: false,
          };
        });
        if (key in dataTypes) {
          dataTypes[key].add(dType);
        } else {
          dataTypes[key] = new Set([dType]);
        }
      } else {
        values[key] = [];
      }
    }

    seriesArr.push({
      collapsed: false,
      hidden: false,
      value: values,
    });
  }

  data[path] = {
    isVisible: true,
    items: seriesArr,
  };

  return { data: data, dataTypes: dataTypes };
}

function buildDefaultData(
  rows,
  dataMap,
  dataColumnsBlackList,
  dimensionsControls
) {
  let data = {};
  let dataTypes = {};

  for (let key in dataMap[0]) {
    const path = dimensionsControls[key].path;
    if (dataMap[0][key].seriesType) {
      // if we have series
      const columnsIndexes = dataMap[0][key].value;
      const seriesCount = columnsIndexes.length;
      let seriesArr: any = [];
      let values: any = [];
      dataTypes[key] = new Set();

      if (seriesCount) {
        // if series not null
        for (let i = 0; i < seriesCount; i++) {
          const dataType = dataMap[0][key].dataType[i];
          // get one series (like area or line)
          if (isColumnValid(columnsIndexes[i], dataColumnsBlackList[0])) {
            seriesArr = {
              value: {
                [key]: rows.map((item) => {
                  const v =
                    dataType === "number" && item[columnsIndexes[i]] === ""
                      ? null
                      : item[columnsIndexes[i]];
                  return {
                    value: v,
                    hidden: false,
                  };
                }),
              },
            };
            values.push(seriesArr);
            dataTypes[key].add(dataType);
          }
        }
      }
      data[path] = { isVisible: true, items: values };
    } else {
      // if not series
      const columnIndex = dataMap[0][key].value;
      let seriesArr: any = [];
      dataTypes[key] = new Set();

      if (isColumnValid(columnIndex, dataColumnsBlackList[0])) {
        const dataType = dataMap[0][key].dataType;
        seriesArr = rows.map((item) => {
          const v =
            dataType === "number" && item[columnIndex] === ""
              ? null
              : item[columnIndex];
          return {
            value: v,
            hidden: false,
          };
        });
        dataTypes[key].add(dataType);
      }
      data[path] = {
        [key]: seriesArr,
      };
    }
  }

  return { data: data, dataTypes: dataTypes };
}

const SeriesStepper = (props) => {
  const { seriesCount, handleStepperDec, handleStepperInc } = props;
  return (
    <ToggleButtonGroup size="small" style={{ marginLeft: 8 }}>
      <ToggleButton
        style={{ padding: 0, width: 40 }}
        color="primary"
        onClick={handleStepperDec}
        disabled={seriesCount === 1}
        value="Decrement"
      >
        <RemoveIcon
          fontSize="small"
          color={"primary"}
          style={{ opacity: seriesCount === 1 ? 0.25 : 1 }}
        />
      </ToggleButton>
      <ToggleButton
        style={{ padding: 0, width: 40 }}
        color="primary"
        onClick={handleStepperInc}
        disabled={seriesCount === 10}
        value="Increment"
      >
        <AddIcon
          fontSize="small"
          color={"primary"}
          style={{ opacity: seriesCount === 10 ? 0.25 : 1 }}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const SeriesSelector = (props) => {
  const {
    seriesCount,
    currSeries,
    handleSetCurrSeries,
    handleStepperDec,
    handleStepperInc,
  } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 16, marginBottom: 16 }}
    >
      <Grid item>
        <Typography variant="subtitle1">Series: </Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup size="small" style={{ marginLeft: 8 }}>
          {[...Array(seriesCount)].map((_, index) => {
            return (
              <ToggleButton
                key={index}
                disabled={currSeries === index}
                onClick={() => handleSetCurrSeries(index)}
                sx={{
                  backgroundColor: "",
                  transition: "background-color 250ms",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, .1)",
                  },
                }}
                style={{
                  padding: 0,
                  width: 40,
                  color: currSeries === index ? "#ffffff" : "#333333",
                  backgroundColor: currSeries === index ? "#333333" : "",
                }}
                color="primary"
                value="X"
              >
                {index + 1}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
      <Grid>
        <SeriesStepper
          seriesCount={seriesCount}
          handleStepperDec={handleStepperDec}
          handleStepperInc={handleStepperInc}
        />
      </Grid>
    </Grid>
  );
};

const DimensionTypeSelector = (props) => {
  const { dimensionsControls, chartDimensionsTypes, chartUI } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {Object.keys(chartDimensionsTypes).map((item, i) => {
        const isSeries = dimensionsControls[item].seriesType;
        const isOnlyNumbers = dimensionsControls[item].options.length === 1;
        const controlTitle = dimensionsControls[item].title;
        const title =
          typeof controlTitle === "function"
            ? controlTitle({}, chartUI)
            : controlTitle;
        let str = "";
        if (isSeries) {
          str = title + " — could be several columns";
        }
        if (isOnlyNumbers) {
          str += isSeries ? ", but only numbers" : title + " — only numbers";
        }
        return str !== "" ? (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 32,
            }}
          >
            {str}
          </div>
        ) : null;
      })}
    </Grid>
  );
};

const HierarchyGrouping = (props) => {
  const { selectedColumns, handleUpdateDataMap } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <div style={{ display: "flex", alignItems: "center", marginRight: 32 }}>
        <div style={{ marginRight: 4 }}>Hierarchy order:</div>
        {selectedColumns.length > 0 ? (
          <ReactSortable
            list={selectedColumns.map((item) => {
              return { id: item };
            })}
            setList={handleUpdateDataMap}
            style={{ display: "contents" }}
          >
            {selectedColumns.map(function (item, i) {
              return (
                <div
                  key={item}
                  className="buttonDrag"
                  style={{
                    marginLeft: 4,
                    padding: "4px 8px 4px 4px",
                    border: "1px solid rgb(224,224,224)",
                    borderRadius: 4,
                  }}
                >
                  <DragIndicator
                    color="secondary"
                    style={{ fontSize: ".75rem", marginRight: 4 }}
                  />
                  {numberToLetters(item)}
                </div>
              );
            })}
          </ReactSortable>
        ) : (
          <span
            style={{
              marginLeft: 4,
              color: "#999",
            }}
          >
            choose columns in table
          </span>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 32,
          marginTop: 8,
        }}
      >
        <Grid item>Weights — numbers only</Grid>
      </div>
    </Grid>
  );
};

const FirstRowSwitcher = (props) => {
  const { isFirstRowHeader, handleSetFirstRowHeader } = props;
  return (
    <Typography style={{ textAlign: "right" }}>
      Use first row as column's names{" "}
      <Switch
        checked={isFirstRowHeader}
        color={isFirstRowHeader ? "primary" : "default"}
        inputProps={{ "aria-label": "checkbox with default color" }}
        onChange={handleSetFirstRowHeader}
      />
    </Typography>
  );
};

// Get dimension controls object from chart's config like
//
// {
//   X: {
//     type: ControlType.Enum,
//     seriesType: false,
//     title: "X",
//     path: "dataX",
//     options: [ControlType.String, ControlType.Number],
//   },
//   Y: {
//     type: ControlType.Enum,
//     seriesType: true,
//     title: "Y",
//     path: "dataSeries",
//     options: [ControlType.String, ControlType.Number],
//   },
// },
//
//and returns object like
//
// {
//   X: {
//     seriesType: false,
//     title: "X",
//     value: null,
//     dataType: undefined,
//   },
//   Y: {
//     seriesType: true,
//     title: "Y",
//     value: [],
//     dataType: undefined,
//   },
// },
//
// value - column's number or numbers, if data is series from uploaded file data table
// default values are: null or empty array
//
function createChartDimensionItem(dimensionsControls, chartUI) {
  let obj: any = {};
  for (let key in dimensionsControls) {
    const title = dimensionsControls[key].title;
    const seriesType = dimensionsControls[key].seriesType;
    obj[key] = {
      value: seriesType ? [] : null,
      title: typeof title === "function" ? title({}, chartUI) : title,
      seriesType: seriesType,
      dataType: seriesType ? [] : undefined,
    };
  }
  return obj;
}

export function FilePreviewTableSettings(props) {
  const {
    tableRows,
    isFirstRowHeader,
    // numbersLocale,
    chartDimensionsTypes,
    dimensionsControls,
    handleParseErrors,
    handleSetFirstRowHeader,
    isScatter,
    isHierarchy,
    isSankey,
  } = props;

  const dispatch = useDispatch();

  const chartUI = useSelector((state: any) => state.chart.chartUI);

  const [seriesCount, setSeriesCount] = React.useState(1);
  const [currSeries, setCurrSeries] = React.useState(0);
  const [isEdited, setIsEdited] = React.useState(false);
  const [isFirstEditDone, setIsFirstEditDone] = React.useState(false);
  const [didMount, setDidMount] = React.useState(false);
  const [dataMap, setDataMap] = React.useState([
    createChartDimensionItem(dimensionsControls, chartUI),
  ]);
  // black-list of chosen column's from uploaded file
  const [dataColumnsBlackList, setDataColumnsBlackList] = React.useState([[]]);

  const rowsCount = tableRows ? tableRows.length : 0;
  const columnsCount = rowsCount > 0 ? Object.keys(tableRows[0]).length : 0;
  const MAX_ROWS = 5;
  const tRows = rowsCount > 0 ? tableRows.slice(0, MAX_ROWS) : [];

  function setColumnBg(column) {
    const color = "rgba(0,0,0,.05)";
    const errorColor = "rgba(255,255,0,.25)";
    const headerTogglesColor = "rgba(0,0,0,.5)";
    const headerColumnsTypessColor = "rgba(0,0,0,.05)";
    // highlight table header with toggles
    headerToggleCellsRefs[column].current!.style.background =
      headerTogglesColor;
    // highlight table header with column's types
    headerColumnsTypesCellsRefs[column].current!.style.background =
      headerColumnsTypessColor;
    //main table
    for (var i = 0; i < bodyCellsRefs.length; i++) {
      // background
      bodyCellsRefs[i][column].current.style.background = dataColumnsBlackList[
        currSeries
      ].some((item) => item === column)
        ? errorColor
        : color;
      // borders
      bodyCellsRefs[i][column].current.style.borderBottom =
        dataColumnsBlackList[currSeries].some((item) => item === column)
          ? "solid 1px rgba(160,160,0,.25)"
          : "solid 1px rgba(224,224,224,1)";
    }
  }

  function removeColumnBg(column) {
    // remove hover from table header with toggles
    headerToggleCellsRefs[column].current!.style.background = "";
    // remove hover from table header with column's types
    headerColumnsTypesCellsRefs[column].current!.style.background = "";
    //main table
    for (var i = 0; i < bodyCellsRefs.length; i++) {
      // background
      bodyCellsRefs[i][column].current.style.background = "";
    }
  }

  const addHover = (column) => {
    setColumnBg(column);
  };
  const removeHover = (column) => {
    const isChecked = Object.values(dataMap[currSeries]).some((elem: any) => {
      if (elem.seriesType) {
        return elem.value.some((item) => item === column);
      } else {
        return elem.value === column;
      }
    });
    if (!isChecked) {
      removeColumnBg(column);
    }
  };

  // Store mapping dimension and column number (or numbers, if series)
  // from uploaded file data table when column chosen
  //
  // {
  //   X: {
  //     seriesType: false,
  //     title: "X",
  //     value: 1, // column's number
  //     dataType: string,
  //   },
  //   Y: {
  //     seriesType: true,
  //     title: "Y",
  //     value: [0, 2, ...], // column's numbers
  //     dataType: [number],
  //   },
  // },
  //
  const handleSetDataMap = (dimension, column, dataType) => {
    const obj = { ...dataMap[currSeries] };
    for (let key in obj) {
      // if we have an array
      if (dimensionsControls[key].seriesType) {
        // save or remove column index from chosen dimension
        if (key === dimension) {
          if (obj[key].value.some((item) => item === column)) {
            const index = obj[key].value.indexOf(column);
            obj[key].value.splice(index, 1);
            obj[key].dataType.splice(index, 1);
          } else {
            obj[key].value.push(column);
            obj[key].dataType.push(dataType);
          }
        } else {
          if (obj[key].value.some((item) => item === column)) {
            const index = obj[key].value.indexOf(column);
            obj[key].value.splice(index, 1);
            obj[key].dataType.splice(index, 1);
          }
        }
      } else {
        // if we have single values
        if (key === dimension) {
          if (obj[key].value === column) {
            obj[key].value = null;
            obj[key].dataType = undefined;
          } else {
            obj[key].value = column;
            obj[key].dataType = dataType;
          }
        } else {
          if (obj[key].value === column) {
            obj[key].value = null;
            obj[key].dataType = undefined;
          }
        }
      }
    }
    let newDataMap = [...dataMap];
    newDataMap[currSeries] = obj;
    setDataMap(newDataMap);
    setIsEdited(!isEdited);
    setIsFirstEditDone(true);
  };

  // update datamap if columns order changed
  const handleUpdateDataMap = (indexes) => {
    const obj = { ...dataMap[currSeries] };
    for (let key in obj) {
      //if we have hierarchy
      if (key === "Hierarchy") {
        for (let i = 0; i < indexes.length; i++) {
          obj[key].value[i] = indexes[i].id;
        }
      }
    }
    let newDataMap = [...dataMap];
    newDataMap[currSeries] = obj;
    setDataMap(newDataMap);
    setIsEdited(!isEdited);
  };

  function updateStoreData() {
    const rows = isFirstRowHeader ? tableRows.slice(1) : tableRows; // get data from uploaded file
    const chartType = isScatter ? "Scatter" : isSankey ? "Sankey" : "";
    const { data, dataTypes } = buildDataByChartType(
      chartType,
      rows,
      dataMap,
      dataColumnsBlackList,
      dimensionsControls
    );

    let types = {};
    for (let key in dataTypes) {
      switch (dataTypes[key].size) {
        case 0:
          types[key] = undefined;
          break;
        case 1:
          types[key] = dataTypes[key].values().next().value;
          break;
        case 2:
          types[key] = "string";
      }
    }

    for (let key in data) {
      if (Array.isArray(data[key].items)) {
        // if we have series, remove group and then, add new one
        const seriesCount = chartUI[key].items.length;
        for (let i = 0; i < seriesCount; i++) {
          dispatch(REMOVE_GROUP(key, 0));
        }
        for (let i = 0; i < data[key].items.length; i++) {
          dispatch(ADD_GROUP(key));
          dispatch(
            UPDATE_CHART_SETTINGS(
              key + ".items." + i + ".value",
              data[key].items[i].value
            )
          );
        }
      } else {
        // if not series
        dispatch(UPDATE_CHART_SETTINGS(key, data[key]));
      }
    }

    dispatch(UPDATE_CHART_SETTINGS("dimensions", types));
  }

  // Validate column's values for chosen type "number"
  // Check dimension controls and if we have only one option - "number",
  // then we validate data
  //
  // Empty cells ("") will be ignored - why "" in number type?
  function validateColumns() {
    const obj = { ...dataMap[currSeries] }; // get mapping
    const rows = isFirstRowHeader ? tableRows.slice(1) : tableRows; // get data rows
    let errorColumns: any = []; // array for not valide columns

    for (let key in obj) {
      const dimensionTypes = dimensionsControls[key].options;
      let isValid = true;

      // if we have only one option and it's "number"
      if (dimensionTypes.length === 1 && dimensionTypes[0] === "number") {
        if (dimensionsControls[key].seriesType) {
          for (let i = 0; i < obj[key].value.length; i++) {
            const column = rows.map((item) => {
              return item[obj[key].value[i]];
            });
            isValid = column
              .filter((elem) => elem !== "")
              .every((item) => typeof item === "number");
            if (!isValid) {
              errorColumns.push(obj[key].value[i]);
            }
          }
        } else {
          const column = rows.map((item) => {
            return item[obj[key].value];
          });
          isValid = column
            .filter((elem) => elem !== "")
            .every((item) => typeof item === "number");
          if (!isValid) {
            errorColumns.push(obj[key].value);
          }
        }
      }
    }
    let warnings = [...dataColumnsBlackList];
    warnings[currSeries] = errorColumns || [];
    setDataColumnsBlackList(warnings);
  }

  let headerToggleCellsRefs = tRows[0]
    ? Object.keys(tRows[0]).map(() => {
        return React.createRef<HTMLTableCellElement>();
      })
    : [];

  let headerColumnsTypesCellsRefs = tRows[0]
    ? Object.keys(tRows[0]).map(() => {
        return React.createRef<HTMLTableCellElement>();
      })
    : [];

  let bodyCellsRefs = tRows[0]
    ? tRows.map(() => {
        return Object.keys(tRows[0]).map(() => {
          return React.createRef<HTMLTableCellElement>();
        });
      })
    : [];

  React.useEffect(() => {
    // skipping first render or switching first row header before any data chosen
    if (didMount && isFirstEditDone) {
      validateColumns();
      updateStoreData();
    }
  }, [isEdited, isFirstRowHeader]);

  React.useEffect(() => {
    handleParseErrors(dataColumnsBlackList ? true : false);
  }, [dataColumnsBlackList]);

  React.useEffect(() => {
    //remove all highlights
    for (let i = 0; i < columnsCount; i++) {
      removeColumnBg(i);
    }
    //if some data chosen, highlight column
    for (let key in dataMap[currSeries]) {
      const elem = dataMap[currSeries][key];
      if (elem.seriesType) {
        for (let i = 0; i < elem.value.length; i++) {
          setColumnBg(elem.value[i]);
        }
      } else {
        if (elem.value !== null) {
          setColumnBg(elem.value);
        }
      }
    }
  });

  React.useEffect(() => {
    setDidMount(true);
  }, []);

  // Remove last series
  const handleStepperDec = () => {
    if (currSeries === seriesCount - 1) {
      setCurrSeries(currSeries - 1);
    }
    setSeriesCount(seriesCount - 1);
    const arr = dataMap.slice(0, -1);
    setDataMap(arr);
    const warnings = dataColumnsBlackList.slice(0, -1);
    setDataColumnsBlackList(warnings);
  };
  // Add one more series
  const handleStepperInc = () => {
    setSeriesCount(seriesCount + 1);
    const arr = [
      ...dataMap,
      createChartDimensionItem(dimensionsControls, chartUI),
    ];
    setDataMap(arr);
    const warnings = [...dataColumnsBlackList, []];
    setDataColumnsBlackList(warnings);
  };

  const handleSetCurrSeries = (curr) => {
    setCurrSeries(curr);
  };

  return tRows.length ? (
    <>
      <Grid
        container
        style={{ marginBottom: 8, marginTop: 16 }}
        alignItems="flex-end"
      >
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          {!isHierarchy ? (
            <DimensionTypeSelector
              chartDimensionsTypes={chartDimensionsTypes}
              dimensionsControls={dimensionsControls}
              chartUI={chartUI}
            />
          ) : (
            <HierarchyGrouping
              selectedColumns={[...dataMap[0].Hierarchy.value]}
              handleUpdateDataMap={handleUpdateDataMap}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <FirstRowSwitcher
            isFirstRowHeader={isFirstRowHeader}
            handleSetFirstRowHeader={handleSetFirstRowHeader}
          />
        </Grid>
      </Grid>
      {isScatter ? (
        <SeriesSelector
          seriesCount={seriesCount}
          currSeries={currSeries}
          handleSetCurrSeries={handleSetCurrSeries}
          handleStepperDec={handleStepperDec}
          handleStepperInc={handleStepperInc}
        />
      ) : (
        ""
      )}
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <DataTable
          headerToggleCellsRefs={headerToggleCellsRefs}
          headerColumnsTypesCellsRefs={headerColumnsTypesCellsRefs}
          bodyCellsRefs={bodyCellsRefs}
          columnsCount={columnsCount}
          addHover={addHover}
          removeHover={removeHover}
          dataMap={dataMap[currSeries]}
          handleSetDataMap={handleSetDataMap}
          isHierarchy={isHierarchy}
          rows={tRows}
          data={tableRows}
          isFirstRowHeader={isFirstRowHeader}
          dataColumnsBlackList={dataColumnsBlackList[currSeries]}
          rowsCut={rowsCount - MAX_ROWS}
        />
      </TableContainer>
    </>
  ) : (
    <>Something wrong. No data recieved.</>
  );
}

export default FilePreviewTableSettings;
