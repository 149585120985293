import * as React from "react";
import { ControlType } from "./controltype";
import { InputCheckbox } from "./inputCheckbox";
import { InputBoolean } from "./inputBoolean";
import {
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Delete from "@mui/icons-material/Delete";
import ArrowUpWard from "@mui/icons-material/ArrowUpward";
import ArrowDownWard from "@mui/icons-material/ArrowDownward";
import "./rightdrawer.css";

export const groupHeader = (title, name, isControls) => {
  return (
    <div
      className="uiGroupHeader controlItem"
      style={{
        display: "flex",
        flexDirection: "row",
        background: "#e3e3e3",
        padding: 8,
        marginTop: 16,
        marginBottom: 8,
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <div style={{ textAlign: "left", flexGrow: 1, overflow: "hidden" }}>
        <Typography style={{ fontSize: 14 }} noWrap>
          {title}
        </Typography>
      </div>
      {isControls ? (
        <div style={{ width: "120px" }}>
          <InputBoolean
            name={name + ".isVisible"}
            inputSettings={{ enabledTitle: "show", disabledTitle: "hide" }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const collectionHeader = (
  title,
  name,
  isControls,
  isCollectionItemsVisible,
  isCollectionItemsCollapsed,
  itemsCount,
  handleToggleGroups
) => {
  return (
    <div
      className="uiCollectionHeader controlItem"
      style={{
        display: "flex",
        flexDirection: "row",
        background: "#e3e3e3",
        padding:
          isCollectionItemsVisible && itemsCount > 1 ? "3px 8px 3px 0px" : 8,
        marginTop: 16,
        marginBottom: 8,
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "left", flexGrow: 1, overflow: "hidden" }}>
        <Typography style={{ fontSize: 14 }} noWrap>
          {isCollectionItemsVisible && itemsCount > 1 ? (
            <IconButton
              size="small"
              onClick={() =>
                handleToggleGroups(name, !isCollectionItemsCollapsed)
              }
            >
              <Tooltip
                title={
                  (isCollectionItemsCollapsed ? "Expand" : "Collaps") +
                  " all items"
                }
                enterDelay={600}
                enterNextDelay={600}
              >
                <ArrowDropDown
                  style={{
                    color: "#333",
                    transform: isCollectionItemsCollapsed
                      ? "rotate(-90deg)"
                      : "none",
                  }}
                />
              </Tooltip>
            </IconButton>
          ) : null}
          {title}
        </Typography>
      </div>
      {isControls ? (
        <div style={{ width: "120px" }}>
          <InputBoolean
            name={name + ".isVisible"}
            inputSettings={{ enabledTitle: "show", disabledTitle: "hide" }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const collectionItemHeader = (
  title,
  showCheckBox,
  showSortControls,
  name,
  i,
  length,
  isCollapsed,
  isHidden,
  groupColor,
  handleRemoveGroup,
  handleToggleGroup,
  handleMoveGroupUp,
  handleMoveGroupDown
) => {
  const showDeleteControl = showCheckBox && isHidden;
  const isFirst = i === 0;
  const isLast = i === length - 1;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: "#f9f9f9",
        marginTop: isFirst ? -8 : 8,
        marginBottom: 8,
        alignItems: "center",
        gap: "8px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          flexGrow: 1,
          overflow: "hidden",
          marginLeft: 8,
        }}
      >
        <Typography style={{ fontSize: 14 }} noWrap>
          {showCheckBox ? (
            <InputCheckbox
              key={i}
              name={name}
              index={i}
              isHidden={isHidden}
              parentType={ControlType.Collection}
            />
          ) : (
            ""
          )}
          <IconButton size="small" onClick={() => handleToggleGroup(name, i)}>
            <ArrowDropDown
              style={{
                color: "#333",
                transform: isCollapsed ? "rotate(-90deg)" : "none",
              }}
            />
          </IconButton>
          {groupColor ? (
            <svg width="12" height="12" style={{ marginRight: 8 }}>
              <circle cx="6" cy="6" r="6" fill={groupColor} />
            </svg>
          ) : (
            ""
          )}
          {title}
        </Typography>
      </div>
      {showSortControls ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 8,
          }}
        >
          {showDeleteControl ? (
            <div>
              <IconButton
                size="small"
                style={{ marginRight: 8 }}
                onClick={() => handleRemoveGroup(name, i)}
              >
                <Delete
                  fontSize="small"
                  className="iconDelete"
                  style={{ color: "#999999" }}
                />
              </IconButton>
            </div>
          ) : (
            ""
          )}
          <div style={{ width: "78px", height: "30px" }}>
            <ToggleButtonGroup
              size="small"
              style={{ width: "100%", marginTop: "4px" }}
            >
              <ToggleButton
                style={{ padding: 0, width: "50%" }}
                color="primary"
                onClick={() => handleMoveGroupUp(name, i)}
                disabled={isFirst}
                value="Up"
              >
                <ArrowUpWard
                  fontSize="small"
                  color={"primary"}
                  style={{ opacity: isFirst ? 0.25 : 1 }}
                />
              </ToggleButton>
              <ToggleButton
                style={{ padding: 0, width: "50%" }}
                color="primary"
                onClick={() => handleMoveGroupDown(name, i)}
                disabled={isLast}
                value="Down"
              >
                <ArrowDownWard
                  fontSize="small"
                  color={"primary"}
                  style={{ opacity: isLast ? 0.25 : 1 }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
