import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import "numeral/locales";
import { numberToLetters, getScaleType } from "../utils/utils";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    fontSize: "14px",
  },
}));

const TooltipWarning = () => {
  return (
    <React.Fragment>
      <Typography color="inherit">Sorry, wrong data format</Typography>
    </React.Fragment>
  );
};

const TooltipDimensions = () => {
  return (
    <React.Fragment>
      <Typography color="inherit">Column's data type autodetect</Typography>
    </React.Fragment>
  );
};

export const DataTable = (props) => {
  const {
    headerToggleCellsRefs,
    headerColumnsTypesCellsRefs,
    bodyCellsRefs,
    columnsCount,
    addHover,
    removeHover,
    dataMap,
    handleSetDataMap,
    isHierarchy,
    rows,
    data,
    isFirstRowHeader,
    dataColumnsBlackList,
    rowsCut,
  } = props;

  const columnsDataTypes = Array.from(new Array(columnsCount)).map(
    (item, j) => {
      // define whole column's type
      let columnData: any = [];
      for (let i = isFirstRowHeader ? 1 : 0; i < data.length; i++) {
        columnData.push(data[i][j]);
      }
      return getScaleType(columnData);
    }
  );

  const RenderTableBody = () => {
    return (
      <TableBody>
        {rows.map((row, i) => {
          return (
            <TableRow
              key={i}
              style={{
                backgroundColor:
                  i === 0 && isFirstRowHeader ? "rgba(224,224,224,1)" : "",
              }}
            >
              {row.map((item, j) => {
                return (
                  <HtmlTooltip
                    title={
                      dataColumnsBlackList.some((elem) => elem === j) ? (
                        <TooltipWarning />
                      ) : (
                        ""
                      )
                    }
                    key={j}
                  >
                    <TableCell
                      ref={bodyCellsRefs[i][j]}
                      style={{ transition: "background 250ms" }}
                      onMouseEnter={() => addHover(j)}
                      onMouseLeave={() => removeHover(j)}
                      key={j}
                    >
                      {i === 0 &&
                      dataColumnsBlackList.some((elem) => elem === j) ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginRight: 16 }}>{row[j]}</div>
                          <div>
                            <span role="img" aria-label="warning">
                              ⚠️
                            </span>
                          </div>
                        </div>
                      ) : (
                        row[j]
                      )}
                    </TableCell>
                  </HtmlTooltip>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const RenderTableHead = () => {
    function getToggleCells() {
      return Array.from(new Array(columnsCount)).map((item, j) => {
        return (
          <TableCell
            ref={headerToggleCellsRefs[j]}
            onMouseEnter={() => addHover(j)}
            onMouseLeave={() => removeHover(j)}
            style={{ transition: "background 250ms", border: "none" }}
            key={j}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup size="small">
                {Object.keys(dataMap).map((key, index) => {
                  const value = Array.isArray(dataMap[key].value)
                    ? dataMap[key].value
                    : [dataMap[key].value];
                  return (
                    <ToggleButton
                      key={index}
                      // disabled={dataMap[key] === "X" && value[0] === j}
                      // disabled={
                      //   dataMap[key].seriesType === false && value[0] === j
                      // }
                      sx={{
                        backgroundColor: "",
                        transition: "background-color 250ms",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, .1)",
                        },
                      }}
                      style={{
                        padding: "0 8px 0 8px",
                        minWidth: 40,
                        textTransform: "none",
                        color: value.some((elem) => elem === j)
                          ? "#333"
                          : "#fff",
                        backgroundColor: value.some((elem) => elem === j)
                          ? "#fff"
                          : "",
                        border: "solid 1px rgba(255, 255, 255, .2)",
                      }}
                      color="primary"
                      onClick={() =>
                        handleSetDataMap(key, j, columnsDataTypes[j])
                      }
                      value="X"
                    >
                      {!isHierarchy
                        ? dataMap[key].title
                        : key === "Hierarchy"
                        ? numberToLetters(j)
                        : key}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
          </TableCell>
        );
      });
    }

    function getColumnsTypesCells() {
      return Array.from(new Array(columnsCount)).map((item, j) => {
        const dataType = columnsDataTypes[j];
        return (
          <TableCell
            ref={headerColumnsTypesCellsRefs[j]}
            onMouseEnter={() => addHover(j)}
            onMouseLeave={() => removeHover(j)}
            style={{ transition: "background 250ms", border: "none" }}
            key={j}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <HtmlTooltip title={<TooltipDimensions />} key={j}>
                <span
                  style={{
                    backgroundColor: "white",
                    color: "rgba(0,0,0,.5)",
                    borderRadius: 8,
                    fontSize: 12,
                    lineHeight: 1.4,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                >
                  {dataType === "string" ? "category" : dataType}
                </span>
              </HtmlTooltip>
            </div>
          </TableCell>
        );
      });
    }

    return (
      <TableHead>
        <TableRow style={{ backgroundColor: "#333" }}>
          {getToggleCells()}
        </TableRow>
        <TableRow style={{ backgroundColor: "#ccc" }}>
          {getColumnsTypesCells()}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Table size="small">
      {rowsCut > 0 ? <caption>and {rowsCut} more rows (cut)…</caption> : null}
      <RenderTableHead />
      <RenderTableBody />
    </Table>
  );
};
