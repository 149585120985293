import React from "react";
import {
  Grid,
  Select,
  SelectChangeEvent,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";

export function InputEnum(props) {
  const { name: nameOrigin, inputSettings } = props;

  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);

  let OPTIONS: string[] = [];
  let TITLES: string[] = [];
  const PLACEHOLDER = inputSettings ? inputSettings.placeholder || "" : "";
  const USE_REF =
    typeof inputSettings.useRef === "function"
      ? inputSettings.useRef(chartSettings)
      : false;
  const REF_NAME = inputSettings.refName || undefined;

  let name = nameOrigin;

  // if reference is set, use it to get and put value there
  if (USE_REF && REF_NAME) {
    const arr = nameOrigin.split(".");
    arr.splice(-1);
    name = arr.join(".") + "." + inputSettings.refName;
  }

  const settingsValue = getObjValueByPath(chartUI, name);

  const fonts = useSelector((state: any) => state.googleFonts);
  const dispatch = useDispatch();

  let namePathArr = name.split(".");

  // hack - cannot dinamic update variants by choosing font
  const isTitleOrCaptionFontFamily =
    (namePathArr[0] === "title" || namePathArr[0] === "caption") &&
    namePathArr[namePathArr.length - 1] === "fontVariants";

  if (isTitleOrCaptionFontFamily) {
    namePathArr.pop();
    const namePathFontFamily = namePathArr.join(".") + ".fontFamily";
    OPTIONS =
      fonts.length > 0
        ? fonts.find(
            (item) =>
              item.family === getObjValueByPath(chartUI, namePathFontFamily)
          ).variants
        : [];
  }
  // end hack
  else {
    // if options is function, execute it with chart's settings and index as prop
    OPTIONS =
      typeof inputSettings.options === "function"
        ? inputSettings.options(chartSettings)
        : inputSettings.options || [];

    TITLES =
      typeof inputSettings.optionTitles === "function"
        ? inputSettings.optionTitles(chartSettings)
        : inputSettings.optionTitles || [];
  }

  const VALUE = OPTIONS.find((elem) => elem === settingsValue);

  function handleChange(event: SelectChangeEvent) {
    const value = event.target.value as string;
    dispatch(UPDATE_CHART_SETTINGS(name, value));
  }

  function handleChangeSegmentedControl(value) {
    dispatch(UPDATE_CHART_SETTINGS(name, value));
  }

  const renderToggle = (
    <ToggleButtonGroup size="small" style={{ width: "100%" }}>
      {OPTIONS.map(function (item, i) {
        return (
          <ToggleButton
            key={i}
            disabled={VALUE === item}
            style={{
              padding: 0,
              width: "50%",
              textTransform: "none",
              color: VALUE === item ? "#ffffff" : "#333333",
              backgroundColor: VALUE === item ? "#333333" : "",
            }}
            color="primary"
            onClick={() => handleChangeSegmentedControl(item)}
            value={item}
          >
            {TITLES ? (TITLES.length > 0 ? TITLES[i] : item) : item}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );

  const renderSelect = (
    <Select
      variant="standard"
      sx={{
        width: "100%",
        textAlign: "left",
      }}
      SelectDisplayProps={{ style: { padding: 0, fontSize: "0.8125rem" } }}
      value={VALUE === undefined ? "" : VALUE}
      displayEmpty
      onChange={handleChange}
    >
      <MenuItem key="menuItem-placeholder" value="" disabled>
        <em>{PLACEHOLDER}</em>
      </MenuItem>
      {OPTIONS.map(function (item, i) {
        return (
          <MenuItem key={i} value={item}>
            {TITLES ? (TITLES.length > 0 ? TITLES[i] : item) : item}
          </MenuItem>
        );
      })}
    </Select>
  );

  return (
    <Grid container alignItems="center">
      {inputSettings.displaySegmentedControl ? renderToggle : renderSelect}
    </Grid>
  );
}

export default InputEnum;
