import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { OPEN_POPUP, IS_CURRENT_CHART_SAVED } from "../utils/actions";
import { Grid, Drawer, Button, Typography } from "@mui/material";
import FilterDramaOutlinedIcon from "@mui/icons-material/FilterDramaOutlined";
import {
  getFirestore,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import DropDown from "./dropdown";
import ChartSettings from "./chartSettings";
import { ChartMenu } from "./chartMenu";
import { ActionBar } from "./actionBar";
import "./rightdrawer.css";

// draws right sidebar with chart's controls
export const RightDrawer = (props) => {
  const { drawerWidth, enableResize } = props;

  const isOpened = useSelector((state: any) => state.isDrawerRightOpened);
  const isSignedIn = useSelector((state: any) => state.firebase.isSignedIn);
  const collectionCharts = useSelector(
    (state: any) => state.firebase.collectionCharts
  );
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);
  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const currentChartId = useSelector(
    (state: any) => state.firebase.currentChartId
  );
  const currentChartName = useSelector(
    (state: any) => state.firebase.currentChartName
  );
  const isCurrentChartSaved = useSelector(
    (state: any) => state.firebase.isCurrentChartSaved
  );

  const db = getFirestore();
  const dispatch = useDispatch();

  const handleSaveChanges = async () => {
    console.log({ chartUI });
    const chart = {
      chartType: currentChartType,
      chartSettings: chartSettings,
      chartUI: chartUI,
    };
    try {
      const docRef = doc(db, collectionCharts, currentChartId);
      await updateDoc(docRef, {
        chart: chart,
        updated: serverTimestamp(),
      });
      dispatch(IS_CURRENT_CHART_SAVED(true));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleSaveNew = () => {
    dispatch(OPEN_POPUP({ content: "Save" }));
  };

  const SigningChartControls = () => {
    return (
      <>
        <Grid
          container
          style={{
            padding: "16px 0 0 0",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" noWrap>
            {currentChartId ? currentChartName : "Untitled"}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            padding: "8px 0 0 0",
          }}
        >
          <Grid item xs>
            <Button
              disabled={isCurrentChartSaved}
              onClick={currentChartId ? handleSaveChanges : handleSaveNew}
              size="small"
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<FilterDramaOutlinedIcon />}
              style={{
                width: "100%",
                border: "solid 1px rgba(0, 0, 0, .12)",
              }}
            >
              {currentChartId ? "Save changes" : "Save as…"}
            </Button>
          </Grid>
          <Grid item xs={4}>
            {/* "New chart" selector */}
            <ChartMenu
              saveChartAlert={currentChartId && !isCurrentChartSaved}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Drawer
      open={isOpened}
      PaperProps={{ style: { width: drawerWidth } }}
      anchor="right"
      variant="persistent"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: "16px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            paddingBottom: 16,
            borderBottom: "solid 1px rgba(0,0,0,.12)",
          }}
        >
          {/* Profile, save file, share and info buttons */}
          <ActionBar />
          {/* Save changes and new chart controls */}
          {isSignedIn ? <SigningChartControls /> : null}
        </div>
        {/* Chart selector */}
        {isSignedIn ? null : <DropDown />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
        }}
        id="uiChartSettings"
      >
        <ChartSettings />
      </div>
      <div
        style={{
          position: "absolute",
          width: "4px",
          top: "0",
          left: "0",
          bottom: "0",
          cursor: "col-resize",
        }}
        onMouseDown={enableResize}
      ></div>
    </Drawer>
  );
};

export default RightDrawer;
